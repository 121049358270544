body {
  background: url("./images/bgnew.jpg") no-repeat center center;
  background-color: #070709;
  background-size: cover; /* This will make the background image adjust its size */
  color: white;
}

.title {
  color: white;
  margin-bottom: 1 rem;
}

.box {
  margin-top: 50px;
}

.panel {
  margin-top: 2em;
}

.panel-heading {
  xbackground-image: linear-gradient(to left, #38052f, #070709);
  background-color: black;
  color: white;
}

.address-box {
  padding: 3em;
}

.box .title {
  color: #333;
}

.navbar {
  background-color: black;
}

.navbar .navbar-item {
  color: white;
}

.connect-wallet span {
  color: #2b54ed;
}

.connect-wallet:hover {
  background-color: #e1e1e1 !important;
}

.container.main-content {
  max-width: 1000px !important;
}

.faucet-hero-body {
  margin-top: 100px;
}

.withdraw-error {
  color: red;
}

.withdraw-success {
  color: green;
}

.message-error {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border: 1px solid #f5c6cb;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
}

.message-success {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  border: 1px solid #c3e6cb;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
}

.footer {
  background-color: #000;
  padding: 20px 0;
  color: #ecf0f1;
}

.footer a {
  color: #3498db;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}
